import store from './index';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import IUser from '@/types/IUser';

@Module({ namespaced: true, dynamic: true, store, name: 'userStore'})
export default class User extends VuexModule {
  public User: IUser = {
    userid: 0,
    first_name: '',
    last_name: '',
    email_address: '',
    user_wan: '',
    is_address_gmail: false,
    is_activ: 'n',
    auth_for_input_intrare: 'n',
    auth_for_input_intern: 'n',
    auth_for_input_exit: 'n',
    auth_for_upload_scan: 'n',
    auth_todo_repartizare: 'n',
    auth_toview_confidential: 'n',
    auth_for_expeditie: 'n',
    auth_for_search: 'n',
    is_admin: 'n',
    flag: 0,
    last_track_time: '',
    csrf_token: '',
    auth_for_asset:'n',
    auth_for_operatiunipersonal:'n',
    job_id: null,
    rang: 0,
    superior_user_id: null,
    org_unit_id: null,
    auth_for_proiect:'n',
    auth_for_deletedocument:'n',
    auth_for_officialdaysoff: 'n',
    auth_for_recallholiday: 'n',
    auth_for_rightdaysholiday: 'n',
    auth_for_restrictionsholiday: 'n',
    auth_for_monitoringholiday: 'n',
    auth_for_operatiuniacces: 'n',
    auth_for_locatiiarhiva: 'n',
    auth_for_operatiunihr: 'n',
    auth_for_document_portal: 'n',
    auth_for_document_nefinalizat: 'n',
    auth_toview_confidential_general: 'n',
    auth_for_timerequesthr: 'n',
    auth_for_accept_timerequest: 'n',
    auth_for_genreport_timerequesthr: 'n',
    auth_for_operatiuniclienti: 'n',
    auth_for_operatiunievenimente: 'n',
    auth_for_operatiuniconducere: 'n',
    auth_for_managementdezvoltare: 'n',
    auth_for_cancellationholiday: 'n',
    auth_for_insertretroactivholiday: 'n',
    auth_for_deleteallocation: 'n',
    auth_for_reportregistryoffice: 'n',
    auth_for_reportbianalytics: 'n',
    auth_for_certificaturbanism: 'n',
    auth_for_serviciidetransport: 'n',
    auth_for_angajari: 'n',
    auth_for_beneficii: 'n',
    auth_for_medicinamuncii: 'n',
    auth_for_posturi: 'n'
  };

  public sessionIsValid = false;
  public ScreenWidth: number = 1440;
  public ScreenHeight: number = 900;
  public MyImgProfileString64: string = '';

  @Mutation
  public SET_STATUS_SESSION(statusSession: boolean) {
    this.sessionIsValid = statusSession;
  }

  @Action
  public set_status_session(statusSession: boolean) {
    this.context.commit('SET_STATUS_SESSION', statusSession);
  }

  @Mutation
  public SET_USER(puser: IUser) {
    this.User = puser;
  }

  @Action
  public set_user(puser: IUser) {
    this.context.commit('SET_USER', puser);
  }

  @Mutation
  public SET_SCREENWIDTH(pnumber: number) {
    this.ScreenWidth = pnumber;
  }

  @Action
  public set_screenwidth(pnumber: number) {
    this.context.commit('SET_SCREENWIDTH', pnumber);
  }

  @Mutation
  public SET_SCREENHEIGHT(pnumber: number) {
    this.ScreenHeight = pnumber;
  }

  @Action
  public set_screenheight(pnumber: number) {
    this.context.commit('SET_SCREENHEIGHT', pnumber);
  }

  @Mutation
  public SET_CSRF_TOKEN(ptoken: string) {
    this.User.csrf_token = ptoken;
  }

  @Action
  public set_csrf_token(ptoken: string) {
    this.context.commit('SET_CSRF_TOKEN', ptoken);
  }

  @Mutation
  public SET_MYIMGPROFILESTRING64R(pimgstring: string) {
    this.MyImgProfileString64 = pimgstring;
  }

  @Action
  public set_myimgprofilestring64r(pimgstring: string) {
    this.context.commit('SET_MYIMGPROFILESTRING64R', pimgstring);
  }
}
