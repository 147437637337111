<template>
  <q-layout view="hHh lpR fFf">
    <q-header v-if="sessionIsValid" elevated style="background-color: #EEF3FA;color:black;">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="onClickOpenMenuLeftOrRedirectLink"/>

        <q-toolbar-title>
          <div class="row" v-if="$q.platform.is.desktop">
            <span class="title__current__page col-6" style="text-align: left; margin-top: 2px;">{{currentPageTitle}}</span>
            <div class="title__current__page col-6" v-if="['APP_BRANDTEAM'].includes(APP_NAME)" style="text-align: right; height: 35px;">
              <img src="./assets/BrandTeam.svg" style="height: 35px;">
            </div>
          </div>
        </q-toolbar-title>

        <q-input v-if="user.auth_for_search=='y' && !(['APP_BRANDTEAM'].includes(APP_NAME)) && $q.platform.is.desktop" 
          dense 
          outlined 
          v-model="inputFastSearch" 
          @update:model-value="(val: any)=>getOptionsDocumentsFastSearch(val)" 
          ref="refInputFastSearch"
          debounce="500">
          <template v-slot:append>
            <div @click="clearInputFastSearch">
              <q-icon v-if="inputFastSearch === ''" name="search" />
              <q-icon v-else name="clear" class="cursor-pointer" />
            </div>
          </template>
          <q-popup-proxy v-model="refOptionsFastSearch" @show="putFocusBackToFastSearch" persistent no-parent-event no-refocus no-focus>
            <div v-if="loadingData" style="width: 250px; height: 200px; background-color: #EEF3FA;">
              <q-spinner-gears
                color="primary"
                size="3em"
                class="absolute-center"
              />
            </div>
            <q-virtual-scroll v-else :items="optionsFastSearch" dense v-slot="{ item, index }">
              <div class="q-pa-xs" style="min-width: 300px; max-width: 400px; max-height: 600px; background-color: #EEF3FA;">
                <q-list bordered separator style="border: 1px solid #dddddd; border-radius: 5px;">
                  <q-item clickable v-ripple :key="index" style="margin: 2px; padding: 2px;">
                    <q-item-section @click="getDocumentFromFastSearch(item)">
                      <span class="fast__search__label--medium">{{item.id_doc}} / {{item.data_doc}}</span>
                      <span class="fast__search__label--medium" style="white-space: nowrap;">{{item.tip_request}} / {{item.den_registru}}</span>
                      <span class="fast__search__label--thin">{{ item.nume_denumire }}</span>
                      <div v-if="item.ref_nr_doc != null" class="element">
                        <span class="fast__search__label--reference" style="white-space: wrap;">{{ item.ref_nr_doc }} / {{ item.ref_data_doc }} / {{item.ref_den_registru}}</span>
                      </div>
                    </q-item-section>
                  </q-item> 
                </q-list>             
              </div>
            </q-virtual-scroll>
          </q-popup-proxy>
        </q-input>

        <q-btn flat icon-right="person"
          :color="['APP_BRANDTEAM'].includes(APP_NAME) ? 'black' : 'primary'" 
          :class="['APP_BRANDTEAM'].includes(APP_NAME) ? 'text-black' : 'text-blue'" 
          :label="user.last_name + ' ' + user.first_name" @click="onClickOpenOrCloseMenuRight(true)">
          
          <q-menu v-if="!(['APP_BRANDTEAM'].includes(APP_NAME))">
            <div class="row no-wrap q-pa-md">
              <div class="column">
                <div class="text-h6 q-mb-md flex flex-center">Setari utilizator</div>
                <!-- <q-input
                  outlined
                  dense
                  label="Nr.randuri tabele"
                  type="number"                        
                  v-model="nrRanduriTabel"
                  lazy-rules
                  style="max-width: 200px;max-height: 7vh;"                        
                  @click="setNrRanduriTabel"
                  :rules="[
                    (val) => ((val && val > 0) || 'Completati numar de randul!'),                              
                    (val) => ((1*val >= 1 && 1*val <= 100) || 'Numarul de rand maxim acceptabil este 100!')
                  ]"
                /> -->
                <!-- <td class="text__title--medium">
                    <div style="display: flex;">
                        <span>Nr.randuri tabele</span>
                    </div>
                </td> -->
                <div>
                  <div style="display: flex;">
                    <span class="text__title--medium">Nr.randuri tabele:&nbsp;</span>
                    <span class="text__input--bold">{{nrRanduriTabel}}</span>
                  </div>

                  <q-popup-edit v-model="nrRanduriTabel" :validate="val => (val || val > 0) && (1*val >= 1 && 1*val <= 100)">
                      <template v-slot="scope">
                        <q-input
                          autofocus
                          dense
                          v-model="scope.value"
                          style="min-width:300px;"
                          :value="nrRanduriTabel"
                          hint="Nr.randuri tabele"                          
                          :rules="[
                            (val) => ((val && val > 0) || scope.validate(scope.value) || 'Completati numar de randul!'),                              
                            (val) => ((1*val >= 1 && 1*val <= 100) || scope.validate(scope.value) || 'Numarul de rand maxim acceptabil este 100!')
                          ]"                                           
                        >
                          <template v-slot:after>
                              <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                              <q-btn flat dense color="positive" icon="check_circle" @click.stop="setNrRanduriTabel(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                          </template>
                        </q-input>
                      </template>
                  </q-popup-edit>
                </div>

                <div>
                  <q-select outlined 
                    v-model="currentLocale"
                    :options="supportedLocales"
                    @update:model-value="switchLanguage"
                    :option-label="translatedLanguage"
                    :label="t(`locale.label`)"
                    emit-value map-options lazy-rules
                    style="max-height: 7vh;;margin-top: 10px;" dense
                    :input-style="{ fontFamily: 'Nunito Sans', fontWeight: 'bolder', color: 'black' }"
                    :rules="[val => val && val.length > 0 || 'Alegeti limba!']" 
                    readonly
                  />
                </div>

                <q-btn v-if="['APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME)" color="primary" label="Schimbare companie" style="margin-top:10px;display: flex; align-items: center;" @click="openChangeCompany"/> 
                <q-btn v-if="['APP_DEV', 'APP_CV', 'APP_CJCV', 'APP_MAGIC', 'APP_URBANESQ', 'APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME) && user.is_address_gmail == false" color="primary" label="Schimbare parola" style="margin-top:10px;display: flex; align-items: center;" @click="openChangePassword"/>
                <q-btn v-if="['APP_DEV', 'APP_CV', 'APP_CJCV', 'APP_MAGIC', 'APP_URBANESQ', 'APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME)" color="primary" label="Log out" :style="(user.is_address_gmail == true) ? 'margin-top:10px;display: flex; align-items: center;' : 'margin-top:5px;display: flex; align-items: center;'" @click="logOut"/>              
              </div>

              <q-separator vertical inset class="q-mx-lg" />

              <div class="column items-center">
                <div class="q-mt-md q-mb-xs">
                  <span class="text__important">{{user.last_name}} {{user.first_name}}</span>                                 
                  <p class="text-subtitle2" style="white-space:pre-wrap; word-wrap:break-word; max-width: 400px;">
                    {{ user.org_unit_id?.organization_unit_descriere }}
                  </p>
                  <p class="text-subtitle2" style="white-space:pre-wrap; word-wrap:break-word; max-width: 400px;">
                    {{ user.job_id?.job_title }}
                  </p>   
                  <p class="text-subtitle2">
                    {{ user.email_address }}
                  </p>
                  <p class="text-subtitle2" style="white-space:pre-wrap; word-wrap:break-word; max-width: 400px;"
                    v-if="['APP_MEAT', 'APP_MULTICOMPANY'].includes(APP_NAME)">
                    {{ company.denumire }}
                  </p>
                </div>
              </div>
            </div>
          </q-menu>
        </q-btn>

        <q-btn flat icon-right="settings" v-if="['APP_BRANDTEAM'].includes(APP_NAME)" color="black" class="text-black">
          <q-menu>
            <div class="row no-wrap q-pa-md">
              <div class="column">
                <div class="text-h6 q-mb-md flex flex-center">Setari utilizator</div>
                
                <div>
                  <div style="display: flex;">
                    <span class="text__title--medium">Nr.randuri tabele:&nbsp;</span>
                    <span class="text__input--bold" style="color:black">{{nrRanduriTabel}}</span>
                  </div>

                  <q-popup-edit v-model="nrRanduriTabel" :validate="val => (val || val > 0) && (1*val >= 1 && 1*val <= 100)">
                      <template v-slot="scope">
                        <q-input
                          autofocus
                          dense
                          v-model="scope.value"
                          style="min-width:300px;"
                          :value="nrRanduriTabel"
                          hint="Nr.randuri tabele"                          
                          :rules="[
                            (val) => ((val && val > 0) || scope.validate(scope.value) || 'Completati numar de randul!'),                              
                            (val) => ((1*val >= 1 && 1*val <= 100) || scope.validate(scope.value) || 'Numarul de rand maxim acceptabil este 100!')
                          ]"                                           
                        >
                          <template v-slot:after>
                              <q-btn flat dense color="negative" icon="cancel" @click.stop="scope.cancel" />
                              <q-btn flat dense color="positive" icon="check_circle" @click.stop="setNrRanduriTabel(scope)" :disable="scope.validate(scope.value) === false || scope.initialValue === scope.value" />
                          </template>
                        </q-input>
                      </template>
                  </q-popup-edit>
                </div>

                <div v-if="processEnvMode === 'development'">
                  <q-select outlined 
                    v-model="currentLocale"
                    :options="supportedLocales"
                    @update:model-value="switchLanguage"
                    :option-label="translatedLanguage"
                    :label="t(`locale.label`)"
                    emit-value map-options lazy-rules
                    style="max-height: 7vh;;margin-top: 10px;" dense
                    :input-style="{ fontFamily: 'Nunito Sans', fontWeight: 'bolder', color: 'black' }"
                    :rules="[val => val && val.length > 0 || 'Alegeti limba!']" 
                  />
                </div>

                <q-btn color="dark" label="Schimbare parola" style="margin-top:10px;display: flex; align-items: center;" @click="openChangePassword"/>
                <q-btn color="dark" label="Log out" :style="(user.is_address_gmail == true) ? 'margin-top:10px;display: flex; align-items: center;' : 'margin-top:5px;display: flex; align-items: center;'" @click="logOut"/>              
              </div>

              <q-separator vertical inset class="q-mx-lg" />

              <div class="column items-center">
                <div class="q-mt-md q-mb-xs">
                  <span class="text__important">{{user.last_name}} {{user.first_name}}</span>                                 
                  <p class="text-subtitle2" style="white-space:pre-wrap; word-wrap:break-word; max-width: 400px;">
                    {{ user.org_unit_id?.organization_unit_descriere }}
                  </p>
                  <p class="text-subtitle2" style="white-space:pre-wrap; word-wrap:break-word; max-width: 400px;">
                    {{ user.job_id?.job_title }}
                  </p>   
                  <p class="text-h6">
                    {{ user.email_address }}
                  </p>                  
                </div>
              </div>
            </div>
          </q-menu>
        </q-btn>

        <q-btn flat icon="notifications" 
          :color="['APP_BRANDTEAM'].includes(APP_NAME) ? 'black' : 'primary'" 
          :class="['APP_BRANDTEAM'].includes(APP_NAME) ? 'text-black' : 'text-blue'">
          <div style="display: flex;">
            <q-btn flat @click="goToViewWithParamString('Dashboard', 'documente_repartizate')">
              <q-badge color="blue" transparent style="position: relative;top:-5px;">
                {{abbreviateNumber(eventSourceNrBadge.nrOfMyDocuments)}}
                <q-tooltip
                  transition-show="rotate"
                  transition-hide="rotate"
                >
                  Documente repartizate
                </q-tooltip>
              </q-badge>
            </q-btn>
            <q-btn flat @click="goToViewWithParamString('Dashboard', 'aprobari_avizari')">
              <q-badge color="red" transparent style="position: relative;top:-5px;">
                {{abbreviateNumber(eventSourceNrBadge.nrOfTask)}}
                <q-tooltip
                  transition-show="rotate"
                  transition-hide="rotate"
                >
                  Actiuni solicitate
                </q-tooltip>
              </q-badge>
            </q-btn>
          </div>
        </q-btn>

        <div style="text-align: right;margin-right: 0.1em;" v-if="['APP_CV', 'APP_CJCV', 'APP_HR'].includes(APP_NAME)">
          <span v-if="APP_NAME_TITLE === 'MEDIU LIVE'" style="color: lightgreen; font-family: Nunito Sans; font-size: 0.9rem; font-weight: bold;">{{APP_NAME_TITLE}}</span>
          <span v-else style="color: red; font-family: Nunito Sans; font-size: 0.9rem; font-weight: bold;">{{APP_NAME_TITLE}}</span>
        </div>        
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftMenuOpen" side="left" behavior="mobile" overlay elevated v-if="!(['APP_BRANDTEAM'].includes(APP_NAME)) && user.userid != 0">
      <!-- drawer content -->
      <q-list>
        <q-item clickable @click="aboutPopupOpen = true && ['APP_MAGIC'].includes(APP_NAME)">
          <q-item-section avatar v-if="['APP_CV'].includes(APP_NAME)">
            <img src= "./assets/logo_sf_gheorghe.svg" width="250" height="80">
          </q-item-section>
          <q-item-section avatar v-else-if="['APP_CJCV'].includes(APP_NAME)">
            <img src= "./assets/logo_covasna.png" width="200" height="50">
          </q-item-section>
          <q-item-section avatar v-else-if="['APP_HR'].includes(APP_NAME)">
            <img src= "./assets/logo_harghita.jpg" width="250" height="60">
          </q-item-section>
          <q-item-section avatar v-else>
            <div class="center_img">
              <img src="./assets/odocs_logo_mic_ro.png">
            </div>
          </q-item-section>
        </q-item>
        <q-item clickable v-ripple v-close-popup @click="leftMenuOpen = false">
          <q-item-section avatar>
            <q-icon color="primary" name="close" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Inchide</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <MenuApp :userid="user.userid"/>
    </q-drawer>

    <q-drawer v-model="rightMenuOpen" side="right" behavior="mobile" overlay elevated v-if="(['APP_BRANDTEAM'].includes(APP_NAME)) && user.userid != 0">
      <!-- drawer content -->
      <q-list>
        <q-item clickable v-ripple v-close-popup @click="onClickOpenOrCloseMenuRight(false)">
          <q-item-section avatar>
            <q-icon color="black" name="close" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Inchide</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-ripple @click="goToViewWithParamString('Dashboard', 'documente_repartizate')">
          <q-item-section avatar>
            <q-icon color="black" name="dashboard" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Dashboard</q-item-label>
          </q-item-section>
        </q-item>

        <q-expansion-item
          expand-separator
          label="Planning"
          v-if="(user.is_admin=='y' || user.auth_for_operatiunipersonal=='y')"
          icon="warning_amber"                
        >
          <q-item clickable v-ripple @click="goToViewWithParam('PontajPrezenta', 0)">
            <q-item-section avatar>                      
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontajul meu</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParam('MyTimesRequests', 0)">
            <q-item-section avatar>                      
            </q-item-section>
            <q-item-section>
              <q-item-label>Concediile mele</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Planificare meeting</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Rezervare sala</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Notificari"
          v-if="(user.is_admin=='y' || user.auth_for_asset=='y')"
          icon="notifications_none"
        >
          <q-item clickable v-ripple @click="goToViewWithParam('AssetsNotificationsList', 1)">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Rapoarte pagube</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('Pontaje')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Acces cladire</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Flota')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Monitorizare flote</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'IOT')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Echipamente IOT</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Care"
          v-if="(user.is_admin=='y' || user.auth_for_asset=='y')"
          icon="favorite_outlined"
        >
          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Medicina muncii')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Medicina muncii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Telemedicina')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Telemedicina</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Operatiuni HR"
          v-if="(user.is_admin=='y' || user.auth_for_operatiunihr=='y')"
          icon="work_outline"
        >
          <q-item clickable v-ripple @click="goToViewWithParam('PontajPrezenta', 1)">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Pontaje</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_monitoringholiday=='y'" clickable v-ripple @click="goToViewWithParam('AdminPrezenta', 1)">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Monitorizare concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_rightdaysholiday=='y'" clickable v-ripple @click="goToView('DrepturiCO')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Drepturi Concedii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_officialdaysoff=='y'" clickable v-ripple @click="goToView('NomZiLibereOficial')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Administrare zile libere</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('ReportsHR')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Rapoarte</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Medicina muncii')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Medicina muncii</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Operatiuni acces"
          v-if="(user.is_admin=='y' || user.auth_for_operatiuniacces=='y')"
          icon="qr_code_scanner"
        >
          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniacces=='y'" clickable v-ripple @click="goToView('Monitorizare')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Monitorizare acces</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_operatiuniacces=='y'" clickable v-ripple @click="goToView('AlocariEntitatiAcces')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Alocari acces</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Monitorizare"
          v-if="(user.is_admin=='y' || user.auth_for_asset=='y')"
          icon="date_range"
        >
          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Flota')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Flote</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'IOT')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>IOT</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Telemedicina')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Telemedicina</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToViewWithParamString('Assets', 'Medicina muncii')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Medicina muncii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="goToView('AlocariEntitatiAcces')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Sali de sedinte si birouri</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Documente"
          v-if="user.is_admin=='y' || user.auth_for_input_intrare=='y' || user.auth_for_input_exit=='y' || user.auth_for_input_intern=='y'"
          icon="file_present"
        >
          <q-item v-if="user.is_admin=='y' || user.auth_for_search=='y'" clickable v-ripple @click="goToView('Search')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Cautare documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_input_intrare=='y'" clickable v-ripple @click="goToView('Intrare')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Intrare</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_input_exit=='y'" clickable v-ripple @click="goToView('Iesire')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Iesire</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_input_intern=='y'" clickable v-ripple @click="goToView('Intern')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Intern</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_for_document_nefinalizat=='y'" clickable v-ripple @click="goToView('DocumentNefinalizat')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Documente nefinalizate</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_todo_repartizare=='y' || user.auth_for_reportregistryoffice=='y'" clickable v-ripple @click="goToView('DeRepartizat')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Repartizare documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y' || user.auth_todo_repartizare=='y' || user.auth_for_reportregistryoffice=='y'" clickable v-ripple @click="goToView('ToateRepartizate')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Documente repartizate</q-item-label>
            </q-item-section>
            </q-item>

            <!-- <q-item v-if="user.is_admin=='y' || user.auth_for_search=='y'" clickable v-ripple @click="goToView('Reports')">
              <q-item-section avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>Rapoarte registratura</q-item-label>
              </q-item-section>
            </q-item> -->
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Nomenclatoare"
          v-if="user.is_admin=='y'"
          icon="manage_accounts"
        >
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomCateg')">
            <q-item-section avatar>                      
            </q-item-section>
            <q-item-section>
              <q-item-label>Categorii documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomTipDoc')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Tipuri documente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomRegistre')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Registre</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomCategActive')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Categorii active</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomTipActive')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Tipuri active</q-item-label>
            </q-item-section>
          </q-item>
          
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomJobs')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Functii</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="(user.is_admin=='y' || user.auth_for_proiect=='y')" clickable v-ripple @click="goToView('Proiecte')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Proiecte</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomEntitateAcces')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Entitati acces</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('Adrese')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Adrese</q-item-label>
            </q-item-section>
          </q-item>           
          
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomJudet')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Judete</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomLocalitate')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Localitati</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          expand-separator
          label="Administrare"
          v-if="user.is_admin=='y'"
          icon="security"
        >
          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomUsers')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Utilizatori</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomFlux')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Fluxuri</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('NomOrgUnit')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Org Units</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('ParamsTypeFiles')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Parametri atasamente</q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-if="user.is_admin=='y'" clickable v-ripple @click="goToView('LogAdmin')">
            <q-item-section avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Audit log</q-item-label>
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </q-list>
    </q-drawer>
    
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openDocumentDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Document</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowDocument">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <WorkOnDocument :appid="appidDocToWorkOnIt" :withFinalizeButton="withFinalizeButton" :withRepartizareButton="withRepartizareButton" :accesFrom="accesFrom" :closeWindow="closeWindowDocument" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpenReference" v-if="actionName === 'openDocumentDialogReference'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Document</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowDocumentReference">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <WorkOnDocument :appid="appidDocToWorkOnItReference" :withFinalizeButton="withFinalizeButtonReference" :withRepartizareButton="withRepartizareButtonReference" :accesFrom="accesFromReference" :closeWindow="closeWindowDocumentReference" :task="taskReference"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openDecontDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare decont</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowDecont">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditDecontDetail :appid="appidDecontToWorkOnIt" :closeWindow="closeWindowDecont" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openDeplasareDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare deplasare</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowDeplasare">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditDeplasareDetail :appid="appidDeplasareToWorkOnIt" :closeWindow="closeWindowDeplasare" :task="task"/>          
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openCerereDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare cerere</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowCerere">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditCerereDetail :appid="appidCerereToWorkOnIt" :closeWindow="closeWindowCerere" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openReferatDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare referat</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowReferat">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditReferatDetail :appid="appidReferatToWorkOnIt" :closeWindow="closeWindowReferat" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>            
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openPontajPrezentaDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare pontaj prezenta</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowPontajPrezenta">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditPontajPrezentaDetail :appid="appidPontajPrezentaWorkOnIt" :closeWindow="closeWindowPontajPrezenta" :task="task"/>          
        </q-card-section>
      </q-card>      
    </q-dialog>        
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openPontajProiectDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare pontaj proiect</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowPontajProiect">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditPontajProiectDetail :appid="appidPontajProiectToWorkOnIt" :closeWindow="closeWindowPontajProiect" :task="task"/>          
        </q-card-section>
      </q-card> 
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openTimeRequestDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare cerere concediu</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowTimeRequest">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditTimeRequestDetail :appid="appidTimeRequestWorkOnIt" :closeWindow="closeWindowTimeRequest" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openDocumentCetateniDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare solicitari cetateni</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowDocumentCetateni">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>        
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditDocumentCetateniDetail :appid="appidDocumentCetateniToWorkOnIt" :closeWindow="closeWindowDocumentCetateni" :withFinalizeButton="withFinalizeButton"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openProiectDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare proiect</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowProiect">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>        
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditProiectDetail :appid="appidProiectToWorkOnIt" :closeWindow="closeWindowProiect"/>          
        </q-card-section>
      </q-card>
    </q-dialog>    
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openProiectTaskDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare proiect task</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowProiectTask">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>        
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditProiectTaskDetail :appid="appidProiectTaskToWorkOnIt" :is_all="is_all" :closeWindow="closeWindowProiectTask"/>          
        </q-card-section>
      </q-card>
    </q-dialog> 
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openPersonTimeRequest'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Administare cereri de concediu</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowPersonTimeRequest">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <AdminEmployeeTimeRequest :marca="marcaTimeRequestsWorkOnIt" :year="yearTimeRequestsWorkOnIt" :closeWindow="closeWindowPersonTimeRequest" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openAsset'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">{{ (['APP_BRANDTEAM'].includes(APP_NAME)) ? currentPageTitle : 'Asset' }}</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowAsset">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>         
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <WorkOnAsset :appid="appidAssetToWorkOnIt" :closeWindow="closeWindowAsset"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openCertificateUrbanismDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare certificat de urbanism</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowCertificateUrbanism">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditCertificateUrbanismDetail :appid="appidCertificateUrbanismWorkOnIt" :closeWindow="closeWindowCertificateUrbanism" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogOpen" v-if="actionName === 'openServiciiTransportDialog'" persistent :maximized="true" transition-show="slide-left" transition-hide="slide-right">
      <q-card style="min-width: 95vw; max-width: 95vw; min-height: 95vh; max-height: 95vh;">
        <q-bar style="height: 30px;">
          <div class="cursor-pointer">Formular editare servicii de transport</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup @click="closeWindowServiciiTransport">
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section style="min-height: 85vh; max-height: 91vh;" class="scroll">
          <EditServiciiDeTransportDetail :appid="appidServiciiTransportWorkOnIt" :closeWindow="closeWindowServiciiTransport" :task="task"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    
    <q-dialog v-model="visibleDialogEditGrantCompanyDefault" persistent>
      <q-card style="min-width: 60vw;max-width: 90vw;">
        <q-bar>
          <q-icon name="add_box" />
          <div>Companie</div>

          <q-space />

          <q-btn dense flat icon="close" v-close-popup @click="closeWindowCompany">
              <q-tooltip>Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <DialogEditGrantCompanyDefault :fonsave="updateStoreCompanyDefault"/>
        </q-card-section>
      </q-card>
    </q-dialog>
    
    <q-page-container>
      <!-- v-if="!statusNomenclatoare && userStore.User.is_activ== 'y'" -->
      <q-banner inline-actions class="text-white bg-red" v-if="mesajSeLoadingNomenclatoare != ''">
        <div style="text-align: center;">
          {{mesajSeLoadingNomenclatoare}}
        </div>
      </q-banner>
      <router-view />
    </q-page-container>

    <q-dialog
      v-model="showPreviewFile"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-white">
        <q-bar>
          <h5>{{titleForPreviewForm}}</h5>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip transition-show="rotate" transition-hide="rotate">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <q-linear-progress v-if="downloadingFile" query track-color="orange" color="purple" class="q-mt-sm"/>
          <iframe v-if="isPDFFileType" id="iframe_preview_scan" type="application/pdf" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;"></iframe>
          <img v-else id="iframe_preview_scan" width="100%" height="100%" style="width:100%; height:calc(100vh - 70px);overflow: auto;border: none;display: block;"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="aboutPopupOpen" persistent
      transition-show="slide-down"
      transition-hide="slide-up">
      <q-card style="min-width: 60vw;max-width: 90vw;min-height: 30vw;max-height: 30vw;">
        <q-card-section>
          <div class="q-pa-md">
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">LICENȚA DE UTILIZARE PLATFORMA ODOCS</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Cod Licență:</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;"><strong>5915648210220231</strong></p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Denumire: ODOCS - Sistem de management al dezvoltarii aplicatiei Brandteam</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 21022023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: Unlimited</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;"><strong>5915648210220231_29</strong></p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Denumire: ODOCS - BI</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 13062023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: Unlimited</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;"><strong>5915648210220231_1615</strong><br>Denumire: ODOCS - Pachet Office</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; font-size:10pt;">Issue date: 13072023</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Users: 10</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">&nbsp;</p>
            </div>
            <div class="row">
              <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; widows:0; orphans:0; font-size:10pt;">Versiune platforma Odocs: V{{ APP_INFORMATION.version }}</p>
            </div>
            <div class="absolute-bottom-right q-pa-sm">
              <q-btn
                label="Inchide"
                dense flat 
                icon="close" 
                v-close-popup
                size="sm"
                color="primary"
              />
            </div>
          </div> 
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script lang="ts" src="./app.ts" />

<style scoped>
  .element {
    padding: 5px 0;
    background: #dfe6f1;
  }
  /* .element:before {
    content: "\a0";
    display: block;
    padding: 2px 0;
    line-height: 1px;
    border-top: 1px solid #000; 
  } */
  .element p {
    padding: 0 10px;
  }

  .center_img {
    text-align: center;
    width: 250px;
    height: 50px;
  }
</style>