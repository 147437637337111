import {defineComponent, defineAsyncComponent, inject, ref} from 'vue';
import {getModule} from 'vuex-module-decorators';
import moment from 'moment';
import {CONFIG_ENV, APP_NAME} from '@/config';
import Nomenclatoare from '@/store/Nomenclatoare';
import {ServiceAssets} from '@/modules/ServiceAssets';
import {ServiceAssetNotifications} from '@/modules/ServiceAssetNotifications';
import User from '@/store/User';
import IUser from '@/types/IUser';
import IAsset from '@/types/IAssets';
import INomCategActive from '@/types/INomCategActive';
import INomTipActive from '@/types/INomTipActive';
import {dateToStringDDMMYYYY, dateToStringDDMonYYYY, getRowNumberTable} from '@/modules/Utils';
import {denPersoanaUtilizatorFromMarca, denOrgUnitFromOrg} from '@/modules/Utils';
import ComboOrgUnit from '@/components/ComboOrgUnit/ComboOrgUnit.vue';
import ComboUser from '@/components/ComboUser/ComboUser.vue';
import IPersoana from '@/types/IPersoana';
import {humanReadableBytes} from '@/modules/Utils';
import {FileUploadService} from 'v-file-upload';
import Notify from 'quasar/src/plugins/Notify.js';;
import LoadingComponent from '@/components/LoadingComponent.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import IAssetNotifications from '@/types/IAssetNotifications';
import IOrgUnit from '@/types/IOrgUnit';
import IScanAssets from '@/types/IScanAssets';

type OrNull<T> = { [K in keyof T]: T[K] | null }
type IAssetNotificationsNull = OrNull<IAssetNotifications>;
// type IAssetNull = OrNull<IAsset>;

interface IAssetNotificationsExtended extends IAssetNotificationsNull {
  denumire_stare: string
}

interface IAssetExtended extends IAsset{ 
  finalizat_de_inregistrat:string|null,
  denumire_categ_active:string|null, 
  scan_documents:IScanAssets[]|null,  
  scan_galeries:IScanAssets[]|null,  
  //notifications:[]|null
}

const qDateProxy0 = ref(null);

const EditAssetNotification = defineAsyncComponent({
  loader: () => import('@/views/Asset/EditAssetNotification.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

const DialogOpenScan = defineAsyncComponent({
  loader: () => import('@/components/DialogOpenScan/DialogOpenScan.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  // The error component will be displayed if a timeout is
  // provided and exceeded. Default: Infinity.
  timeout: 3000,
  delay: 200
});

export default defineComponent({
  name: 'WorkOnAsset',

  components: {
    ComboOrgUnit, ComboUser, DialogOpenScan, EditAssetNotification
  },

  props: {
	  appid: {
      type: Number,
      required: true,
      default: 0,
      readonly: true
    },

    closeWindow: {
      type: Function,
      default: undefined
    }
  },

  setup() {
    const emitter = inject("emitter") as any;
    return {
      emitter,
      qDateProxy0,
      slide: ref(0),
      fullscreen: ref(false)
    }
  },

  data() {
    return {
      assetApp: {
        appid: 0,
        id_asset: 0,
        appid_categ_active: 0,
        appid_tip_active: 0,
        status: '',
        titlu: '',
        descriere: '',
        geo_text: '',
        geo_lat: 0,
        geo_long: 0,
        nr_inventare: '',
        data_achizitie: '',
        id_org_unit: 0,
        denumire_org_unit: '',
        marca_user_deponent: 0,
        den_user_deponent: '',
        state_notification: '',     
        finalizat_de_inregistrat: '',
        denumire_categ_active: '',
        scan_documents:[] as IScanAssets[],
        scan_galeries:[] as IScanAssets[],
        //notifications:[]
      } as IAssetExtended,
      loadingAssetData: false,
      myLocale: CONFIG_ENV.myLocale,
      urlForUpload: CONFIG_ENV.URL_ASSET_SCAN.uploadScan,
      uploadingFileToServer: false,
      loadingNotification: false,
      headers: {} as any,
      nomenclatoareStore: getModule(Nomenclatoare),
      userStore: getModule(User),
      viewDialogSelectPersoana: false,
      viewDialogSelectOrgUnit: false,
      filesSelectedForUpload: [] as any,
      dialogUploadFile: false,
      uploadScanTitle: '',
      isFinalizat: false,
      loadingPdfForDownload: false,
      isDocument: '',
      visibleDialogNotification: false,
      notifications: [] as IAssetNotificationsExtended[],
      visibleDialogCarouselFullScreen: false,
      appid_notification: 0,
      myPagination: {
        rowsPerPage: getRowNumberTable()
      },
      filterAssetsNotificationList: '',
      visibleAssetsNotificationListColumns: ['appid', 'state_notification', 'denumire_categorie_active', 'denumire_tip_active', 'denumire_asset', 'solicitant', 'denumire_eveniment', 'descriere', 'denumire_utilizatori', 'data_termen', 'denumire_stare', 'appid_users', 'scans', 'appid'],      
      assetsNotificationListColumns: [
        { name: 'appid', label: 'Id', field: 'appid', align: 'left', sortable: true },
        { name: 'state_notification', label: 'Alerta', field: '', align: 'left', sortable: true },
        { name: 'denumire_categorie_active', label: 'Categorie', field: 'denumire_categorie_active', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'denumire_tip_active', label: 'Tip', field: 'denumire_tip_active', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'denumire_asset', label: 'Denumire', field: 'denumire_asset', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'solicitant', label: 'Solicitant', field: 'solicitant', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },       
        { name: 'denumire_eveniment', label: 'Eveniment', field: 'denumire_eveniment', align: 'left', sortable: true },
        { name: 'descriere', label: 'Descriere', field: 'descriere', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'denumire_utilizatori', label: 'Responsabil', field: 'denumire_utilizatori', align: 'left', sortable: true, style: 'max-width: 200px', headerStyle: 'max-width: 200px' }, 
        { name: 'data_termen', label: 'Termen', field: 'data_termen', align: 'left', sortable: true },
        { name: 'denumire_stare', label: 'Stare', field: 'denumire_stare', align: 'left', sortable: true },
        { name: 'scans', label: 'Atasamente', field: 'scans', align: 'left', style: 'max-width: 200px', headerStyle: 'max-width: 200px' },
        { name: 'appid', label: 'Actiuni', field: 'appid', align: 'left' }
      ],
    }
  },

  methods: {
    resetAsset(){
      this.assetApp ={
        appid: 0,
        id_asset: 0,
        appid_categ_active: 0,
        appid_tip_active: 0,
        status: '',
        titlu: '',
        descriere: '',
        geo_text: '',
        geo_lat: 0,
        geo_long: 0,
        nr_inventare: '',
        data_achizitie: '',
        id_org_unit: 0,
        denumire_org_unit: '',
        marca_user_deponent: 0,
        den_user_deponent: '',
        state_notification: '',     
        finalizat_de_inregistrat: '',
        scan_documents:[] as IScanAssets[],
        scan_galeries:[] as IScanAssets[],
        //notifications:[]
      } as IAssetExtended;
    },

    getAsset() {
      const vueInst=this;
      ServiceAssets.getAsset(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingAssetData = false;
        if(presponse.status==='success'){
          vueInst.assetApp = JSON.parse(JSON.stringify(presponse.asset));
  
          if(vueInst.assetApp.finalizat_de_inregistrat && vueInst.assetApp.finalizat_de_inregistrat=='y'){
            vueInst.isFinalizat = true;
          }
  
          vueInst.assetApp.data_achizitie = vueInst.assetApp.data_achizitie == null ? '' : dateToStringDDMMYYYY(moment(vueInst.assetApp.data_achizitie.substring(0,10), 'YYYY-MM-DD'));
          // vueInst.assetApp.data_termen = vueInst.assetApp.data_termen == null ? moment(new Date()).format('DD.MM.YYYY') : dateToStringDDMMYYYY(moment(vueInst.assetApp.data_termen.substring(0,10), 'YYYY-MM-DD'));
  
          vueInst.assetApp.denumire_org_unit = vueInst.denOrgUnitFromOrg(Number(vueInst.assetApp.id_org_unit));
          vueInst.assetApp.den_user_deponent = vueInst.denPersoanaUtilizatorFromMarca(Number(vueInst.assetApp.marca_user_deponent));
          vueInst.assetApp.denumire_categ_active = vueInst.getDenTipCategorie(Number(vueInst.assetApp.appid_categ_active));
  
          vueInst.getScanDocuments();
          vueInst.getScanGaleries();
          vueInst.getNotifications();
        }
      })
    },

    inputOrgUnit(){
      this.viewDialogSelectOrgUnit = true;
    },

    inputPersoana(){
      this.viewDialogSelectPersoana = true;
    },

    selectPersoana(persoanaUtilizatoare: IPersoana){
      const vueInst = this;
      vueInst.assetApp.marca_user_deponent = persoanaUtilizatoare.marca;
      vueInst.assetApp.den_user_deponent = persoanaUtilizatoare.nume_prenume;
      vueInst.viewDialogSelectPersoana = false;
  
      this.tryUpdateMarca();
    },

    selectOrgUnit(orgUnit: IOrgUnit){
      const vueInst = this;
      vueInst.assetApp.id_org_unit = orgUnit.id;
      vueInst.assetApp.denumire_org_unit = orgUnit.organization_unit_descriere;
      vueInst.viewDialogSelectOrgUnit = false;
  
      this.tryUpdateOrgUnit();
    },

    tryUpdateStatus(scope:any){
      const vueInst = this;
      if(vueInst.assetApp.status){
        ServiceAssets.updateAsset(vueInst.appid, 'status', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateCategorieActiva(scope:any){
      const vueInst = this;
      if(vueInst.assetApp.appid_categ_active){
        ServiceAssets.updateAsset(vueInst.appid, 'id_categ_active', scope.value.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateTipActiva(scope:any){
      const vueInst = this;
      if(vueInst.assetApp.appid_tip_active){
        ServiceAssets.updateAsset(vueInst.appid, 'id_tip_active', scope.value.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateTitlu(scope:any){
      const vueInst = this;
      if(vueInst.assetApp.titlu){
        ServiceAssets.updateAsset(vueInst.appid, 'titlu', scope.value).then(presponse=>{
          if(presponse.status=='success'){            
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateDescriere(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.descriere){
        ServiceAssets.updateAsset(vueInst.appid, 'descriere', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateLocatie(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.geo_text){
        ServiceAssets.updateAsset(vueInst.appid, 'geo_text', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateLatitudine(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.geo_lat){
        ServiceAssets.updateAsset(vueInst.appid, 'geo_lat', scope.value.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateLongitudine(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.geo_long){
        ServiceAssets.updateAsset(vueInst.appid, 'geo_long', scope.value.toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateNumarInventar(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.nr_inventare){
        ServiceAssets.updateAsset(vueInst.appid, 'nr_inventare', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateDataAchizitie(scope:any){
      const vueInst=this;
      if(vueInst.assetApp.data_achizitie){
        ServiceAssets.updateAsset(vueInst.appid, 'data_achizitie', scope.value).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            scope.set();
          }
        })
      }
    },

    tryUpdateOrgUnit(){
      const vueInst = this;     
      if(vueInst.assetApp.id_org_unit){
        ServiceAssets.updateAsset(vueInst.appid, 'id_org_unit', Number(vueInst.assetApp.id_org_unit).toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });              
            //pfunction();
          }
        })
      }
    },

    tryUpdateMarca(){
      const vueInst = this;
      if(vueInst.assetApp.marca_user_deponent){
        ServiceAssets.updateAsset(vueInst.appid, 'marca_user_deponent', Number(vueInst.assetApp.marca_user_deponent).toString()).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            //pfunction();
          }
        })
      }
    },

    doNothing(){      
    },

    finalizeazaInregistrareAsset(){
      const vueInst = this;
      // ServiceDocument.finalizeazaInregistrareAsset(vueInst.appid).then(
      //   presult =>{
      //     if(presult.status=='success'){
            vueInst.isFinalizat=true;
      //       vueInst.$q.notify({
      //         color: 'teal',
      //         textColor: 'white',
      //         type: 'positive',
      //         message: presult.message,
      //         position: 'top',
      //         timeout: 1000,
      //         html: true
      //       });
      //       if(vueInst.$router.currentRoute.name!='Dashboard'){
      //         vueInst.$router.push({name: 'Dashboard'});
      //       }else{
      //         this.emitter.emit('eventFromChildChanged', {action: 'Asset', accesFrom: ''});
              setTimeout(function(){ vueInst.closeWindow?.();}, 200);
      //       }
      //     }
      //   }
      // );
    },

    openFormUploadFile(pIsDocument:string){
      this.filesSelectedForUpload = [];
      this.uploadScanTitle = '';
      this.dialogUploadFile = true;
      this.isDocument = pIsDocument;
    },

    openFormAssetPlan(){
      this.appid_notification = 0;
      this.visibleDialogNotification = true;
    },

    renuntEditareAssetNotification() {
      this.appid_notification = 0;
      this.visibleDialogNotification = false;
      this.getNotifications();
    },

    getScanDocuments(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceAssets.getScans(Number(vueInst.appid), 'y').then((presponse)=>{
        vueInst.loadingAssetData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.assetApp.scan_documents = JSON.parse(JSON.stringify(presponse.rows)) as IScanAssets[];
        }
      });
    },

    getScanGaleries(){
      const vueInst = this;
      vueInst.uploadingFileToServer = true;
      ServiceAssets.getScans(Number(vueInst.appid), 'n').then((presponse)=>{
        vueInst.loadingAssetData = false;
        vueInst.uploadingFileToServer = false;
        if(presponse.status==='success'){
          vueInst.assetApp.scan_galeries = JSON.parse(JSON.stringify(presponse.rows)) as IScanAssets[];
        }
      });
    },

    getNotifications(){
      const vueInst = this;
      vueInst.loadingNotification = true;
      vueInst.visibleDialogNotification = false;
      ServiceAssetNotifications.getAssetNotifications(Number(vueInst.appid)).then((presponse)=>{
        vueInst.loadingAssetData = false;
        vueInst.loadingNotification = false;
        if(presponse.status==='success'){
          //vueInst.assetApp.notifications = JSON.parse(JSON.stringify(presponse.rows));
          vueInst.notifications = JSON.parse(JSON.stringify(presponse.rows));
        }
      });
    },

    tryUpdateAssetNotification(scope:any, notificaton:IAssetNotifications){
      const vueInst=this;
      notificaton.descriere = scope.value;
      if(notificaton.descriere){
        notificaton.status = 'y';
        ServiceAssetNotifications.postUpdateAssetNotification(notificaton).then(presponse=>{
          if(presponse.status=='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
            this.getNotifications();
          }
        });
        scope.set();
      }
    },

    askIfRemoveScan(pscan:IScanAssets){
      const vueInst = this;
      vueInst.$q.dialog({
        title: 'Confirm',
        message: `Stergeti fisierul reprezentand ${pscan.description}?`,
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        ServiceAssets.deleteScan(pscan.appid, { userid: vueInst.user.userid, token: vueInst.user.csrf_token }).then(presponse=>{
          if(presponse.status==='success'){
            vueInst.$q.notify({
              color: 'teal',
              textColor: 'white',
              type: 'positive',
              message: presponse.message,
              position: 'top',
              timeout: 1000,
              html: true
            });
  
            pscan.is_document == 'y' ? this.getScanDocuments() : this.getScanGaleries();
          }
        });
      })
    },

    prepareForUpload(event:any){
      this.filesSelectedForUpload = event.target.files[0];
    },    

    uploadFile() {
      const vueInst = this;
         
      if (vueInst.uploadScanTitle.length == 0) {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa completati titlu documentului!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      } else if (vueInst.checkIfFileIsSelected()) {
        vueInst.uploadingFileToServer = true;
      
        const fileUpload = new FileUploadService(
          this.urlForUpload,
          this.headers,
          this.onProgress
        );
        
        fileUpload.upload(vueInst.filesSelectedForUpload as File, { appidAsset: vueInst.appid, description: vueInst.uploadScanTitle, userid: vueInst.user.userid, token: vueInst.user.csrf_token, isDocument: vueInst.isDocument})
          .then((e: any) => {
            vueInst.dialogUploadFile = false;          
            vueInst.isDocument == 'y' ? vueInst.getScanDocuments() : vueInst.getScanGaleries();
            if(e.target && e.target.response.status && e.target.response.status=='error'){
              Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: e.target.response.message,
                position: 'top',
                timeout: 3500,
                html: true
              })
            }else{
              vueInst.$q.notify({
                color: 'teal',
                textColor: 'white',
                type: 'positive',
                message: e.target.response.message,
                position: 'top',
                timeout: 1000,
                html: true
              });
              
              ServiceAssets.getScans(Number(vueInst.appid), vueInst.isDocument).then(
                presponse=>{
                  vueInst.isDocument == 'y' ? 
                    vueInst.assetApp.scan_documents = JSON.parse(JSON.stringify(presponse.rows)) : 
                    vueInst.assetApp.scan_galeries = JSON.parse(JSON.stringify(presponse.rows));
                }
              )
            }
          })
          .catch((e: any) => {
          })
      } else {
        Notify.create({
          color: 'orange',
          textColor: 'white',
          type: 'warning',
          message: 'Trebuie sa selectati fisierul!',
          position: 'top',
          timeout: 3500,
          html: true
        });
      }
    },

    onProgress(event:any) {
      // Handdle the progress
    },
  
    prepareCoverPdfForDownload(){
      const vueInst = this;
      vueInst.emitter.emit('eventToPreview', { action: 'show_preview_file', title: 'Coperta', appid: vueInst.assetApp.appid, url_to_get_file: `${CONFIG_ENV.URL_ASSETS.qr_code}` });
    },

    denOrgUnitFromOrg(pOrgId:number){
      return denOrgUnitFromOrg(pOrgId);
    },

    denPersoanaUtilizatorFromMarca(pOrgId:number){
      return denPersoanaUtilizatorFromMarca(pOrgId);
    },

    getDenStatus(pStatusValue: string) {
      // const statusArray = [
      //   //{value: null, label: ''}, 
      //   {value: 'activ', label: 'Activ'}, 
      //   {value: 'inactiv', label: 'Inactiv'}, 
      //   {value: 'in reparatie', label: 'In reparatie'}];
  
      let result = '' as string;
      const Status = this.optionsStatus.find(statusValue =>{
        return statusValue.status == pStatusValue;
      });
  
      if(Status){
        result = Status.label;
      }
  
      return result;      
    },

    getDenTipCategorie(pIdTipCategorie: number): string{
      const vueInst=this;
      let result = '' as string;
      const TipCategorie = vueInst.nomenclatoareStore.NomCategoriiActive.find( tipcategorie =>{
        return tipcategorie.appid == pIdTipCategorie;
      });
  
      if(TipCategorie){
        result = TipCategorie.den_lang_1;
      }
  
      return result;
    },

    getDenTipActive(pIdTipActive: number): string{
      const vueInst=this;
      let result = '' as string;
      const TipActive = vueInst.nomenclatoareStore.NomTipActive.find( tipactive =>{
        return tipactive.appid == pIdTipActive;
      });
  
      if(TipActive){
        result = TipActive.den_lang_1;
      }
  
      return result;
    },

    displayDataTermen(pdata:string) :string{
      if(pdata){
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMMYYYY(day);
      }else{ 
        return '';
      }
    },

    humanReadableBytes(nrPfBytes:number): string{
      return humanReadableBytes(nrPfBytes);
    },    

    checkIfFileIsSelected():boolean{
      return this.filesSelectedForUpload != undefined && this.filesSelectedForUpload.length != 0;
    },

    getLinkToImgAsset(galerie: string): string {
      return CONFIG_ENV.URL_ASSETS.imgForGallery + "/" + galerie;
    },

    onPressFullScreen(){
      this.visibleDialogCarouselFullScreen = true;
    },

    onPressEditRowAssetNotification(iassetnotification: IAssetNotifications) {
      const vueInst = this;
      vueInst.appid_notification = iassetnotification.appid;
      vueInst.visibleDialogNotification = true;
    },

    updateUserAlocat(notification : IAssetNotifications) {
      const vueInst=this;
      notification.appid_user_alocat = this.userStore.User.userid;
      notification.stare = 'inlucru';     
      ServiceAssetNotifications.postUpdateAssetNotification(notification).then(presponse=>{
        if(presponse.status=='success'){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: presponse.message,
            position: 'top',
            timeout: 1000,
            html: true
          });
          vueInst.getNotifications();
        }
      });
    },

    updateAssetNotificationStatus(notificaton:IAssetNotifications){
      const vueInst=this;
      notificaton.status = 'y';
      notificaton.stare = 'finalizat';
      ServiceAssetNotifications.postUpdateAssetNotification(notificaton).then(presponse=>{
        if(presponse.status=='success'){
          vueInst.$q.notify({
            color: 'teal',
            textColor: 'white',
            type: 'positive',
            message: presponse.message,
            position: 'top',
            timeout: 1000,
            html: true
          });
          vueInst.getNotifications();
        }
      });
    },

    displayData(pdata:string) :string{
      if(pdata){        
        const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
        return dateToStringDDMonYYYY(day);
      }else{ 
        return '';
      }
    },

    // public displayDataAchizitie(pdata:string) :string{
    //   if(pdata){
    //     const day = moment(pdata.substring(0,10), 'YYYY-MM-DD');
    //     return dateToStringDDMMYYYY(day);
    //   }else{ 
    //     return '';
    //   }
    // }  

    // public tryUpdateDataTermen(pfunction:any){
    //   const vueInst=this;
    //   if(vueInst.assetApp.data_termen){
    //     ServiceAssets.updateAsset(vueInst.appid, 'data_termen', vueInst.assetApp.data_termen).then(presponse=>{
    //       if(presponse.status=='success'){
    //         vueInst.$q.notify({
    //           color: 'teal',
    //           textColor: 'white',
    //           type: 'positive',
    //           message: presponse.message,
    //           position: 'top',
    //           timeout: 1000,
    //           html: true
    //         });
    //         pfunction();
    //       }
    //     })
    //   }
    // }
  },

  computed: {
    APP_NAME(): string{
      return APP_NAME;
    },

    optionsStatus() {
      const statusArray = [
        {status: 'activ', label: 'Activ'}, 
        {status: 'inactiv', label: 'Inactiv'}, 
        {status: 'in reparatie', label: 'In reparatie'}];
      
      return statusArray;
    },    

    optionsCategorieActive(): INomCategActive[] {
      return this.nomenclatoareStore.NomCategoriiActive.filter((pCategActiv: INomCategActive)=>{
        return pCategActiv.activ == 'y';
      })
    },

    optionsTipActive(): INomTipActive[] {
      return this.nomenclatoareStore.NomTipActive.filter((pTipActiv: INomTipActive)=>{
        return pTipActiv.activ == 'y' && pTipActiv.id_categ_active == this.assetApp.appid_categ_active;
      })
    },        

    user(): IUser {
      return this.userStore.User;
    },
    
    getLinkToQrCode(): string {
      return CONFIG_ENV.URL_ASSETS.qr_code + '/' + this.appid;
    },

    optionsStare() {      
      const stareArray = [  
        {status: 'neinceput', label: 'Neinceput'},        
        {status: 'inlucru', label: 'In lucru'}, 
        {status: 'finalizat', label: 'Finalizat'}
      ];

      return stareArray;
    },
  },
  
  watch: {
    appid: {
      immediate: true,
      handler (newVal: number, oldVal: number) {
        // console.log('onAppidChanged in WorkOnAsset', newVal);
        const vueInst = this;
        vueInst.resetAsset();    
        // vueInst.scan_documents = [];
        // vueInst.scan_galeries = [];
        vueInst.notifications = [];
        vueInst.loadingAssetData = true;
        if(newVal != 0 && vueInst.appid != 0) { 
          vueInst.isFinalizat = false;  
          vueInst.getAsset();    
        }
      }
    }
  }
});